import React, { useEffect, useRef } from "react"
import type { ComponentType } from "react"

export function hideParentIfEmpty(Component): ComponentType {
    return (props) => {
        const ref = useRef<HTMLDivElement>(null)

        useEffect(() => {
            if (
                typeof window === "undefined" ||
                typeof document === "undefined"
            ) {
                // Exit early in server environments
                return
            }

            if (ref.current) {
                const checkVisibility = () => {
                    // Check if there are visible children in the parent element
                    const hasVisibleChildren = Array.from(
                        ref.current.children
                    ).some(
                        (child) =>
                            window.getComputedStyle(child).display !== "none"
                    )

                    // Hide or show the parent element based on child visibility
                    ref.current.style.display = hasVisibleChildren
                        ? "block"
                        : "none"
                }

                checkVisibility()

                // Observe changes in child elements
                const observer = new MutationObserver(checkVisibility)
                observer.observe(ref.current, {
                    childList: true,
                    subtree: true,
                })

                return () => observer.disconnect() // Cleanup observer
            }
        }, [])

        return <Component {...props} ref={ref} />
    }
}
